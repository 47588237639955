import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { HandlerService } from './handler.service';
import { distinct } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrderbookerService {
  private baseUrl: string;
  token: string;
  selected_town_id: number;
  selected_employee_id: number
  selected_orderbooker_id: number

  constructor(
    private http: HttpClient,
    private handlerservice: HandlerService
  ) {
    this.baseUrl = this.handlerservice.getBaseUrl();
  }

  createOrderBookerWithEmp(value) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let final_url = this.baseUrl + `orderbooker/with_employee`;
    console.log
    return this.http.post(final_url, JSON.stringify(value), { headers })
  }

  createPJP(body) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let final_url = this.baseUrl + `pjp/`;
    return this.http.post(final_url, JSON.stringify(body), { headers });
  }

  createPJPRulesMultiple(body) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let final_url = this.baseUrl + `pjp_rule/multiple`;
    return this.http.post(final_url, JSON.stringify(body), { headers });
  }

  createPJPRule(body) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let final_url = this.baseUrl + `pjp_rule/`;
    return this.http.post(final_url, JSON.stringify(body), { headers });
  }

  //GET PJP
  getPJP(employee_id) {
    // https://secret.tasteland.com.pk/pjp/?employee_id=12
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let final_url = this.baseUrl + `pjp/?employee_id=${employee_id}&show_frequent=true`;
    return this.http.get(final_url, { headers });
  }

  //TOGGLE ACTIVE
  toggleActive(id, active: boolean = true) {
    // https://secret.tasteland.com.pk/pjp/toggle_active/1?active=true
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let final_url = this.baseUrl + `pjp/toggle_active/${id}?active=${active}`;
    return this.http.put(final_url, { headers });
  }

  //INACTIVATE PJP RULE
  inactivatePJPRule(body) {
    // https://secret.tasteland.com.pk/pjp_rule/inactivate/
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let final_url = this.baseUrl + `pjp_rule/inactivate/`;
    return this.http.put(final_url, body, { headers });
  }

  //UPDATING ORDERBOOKER
  updateOrderBookerWithEmp(value, id: number) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let final_url = this.baseUrl + `orderbooker/${id}`;
    console.log(final_url)
    return this.http.put(final_url, JSON.stringify(value), { headers })
  }

  getOrderBookers(dist_id?: number, route_id?: number) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let final_url = this.baseUrl + `orderbooker/all`;
    let dist_added: boolean = false;
    if (dist_id) {
      dist_added = true;
      final_url = final_url + `?distribution_id=${dist_id}`
      if (route_id) {
        final_url = final_url + `&route_id=${route_id}`
      }
    }
    if (route_id && !dist_added) {
      final_url = final_url + `?route_id=${route_id}`
    }
    return this.http.get(final_url, { headers })
  }

  //GET FILTERED ORDERBOOKERS on id
  getFilteredOrderBookers(id: number) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let final_url = this.baseUrl + `orderbooker/${id}`;
    return this.http.get(final_url, { headers })
  }

  //Orderbooker Routes
  getOrderbookerRoutes(params: HttpParams) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let final_url = this.baseUrl + `orderbooker_route/all`;
    return this.http.get(final_url, { params, headers })
  }


  //pjp employees
  getPJPEmployees(town_id: number) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let final_url = this.baseUrl + `pjp/employee/${town_id}`;
    return this.http.get(final_url, { headers })
  }

  //pjp shops
  getPJPShops(employee_id: number, params?: HttpParams) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let final_url = this.baseUrl + `pjp/shops/${employee_id}?sort_alphabetically=true&include_priority=true`;
    return this.http.get(final_url, { params, headers })
  }

  getallOrderBookers() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let final_url = this.baseUrl + `orderbooker/all`;

    return this.http.get(final_url, { headers })
  }
  
}
