import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Product } from 'src/app/product/product';
import { LoginService } from './login.service';
import { HandlerService } from './handler.service';

@Injectable(
    { providedIn: 'root' }
)
export class ProductService {
    private baseUrl: string;

    constructor(
        private http: HttpClient,
        private loginService: LoginService,
        private handleService: HandlerService) {
        this.baseUrl = this.handleService.getBaseUrl();
    }

    createProduct(product: Product) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(this.baseUrl + `product/`, JSON.stringify(product), { headers })

    }
    viewProducts() {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(this.baseUrl + `product/all`, { headers })
    }

    //GET A SINGLE PRODUCT
    getProduct(id: number) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(this.baseUrl + `product/${id}`, { headers })
    }
    updateProduct(id: number, product: Product) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.put(this.baseUrl + `product/${id}`, JSON.stringify(product), { headers })
    }

    createSku(sku: Product) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(this.baseUrl + `sku/`, JSON.stringify(sku), { headers })

    }
    viewAllSku() {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(this.baseUrl + `sku/all`, { headers })
    }
    viewSku(id: number) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(this.baseUrl + `sku/${id}`, { headers })
    }
    updateSku(id: number, sku: Product) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.put(this.baseUrl + `sku/${id}`, JSON.stringify(sku), { headers })
    }
    createVariant(variant: Product) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(this.baseUrl + `variant/`, JSON.stringify(variant), { headers })

    }
    viewVariants() {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(this.baseUrl + `variant/all`, { headers })
    }
    viewVariant(id: number) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(this.baseUrl + `variant/${id}`, { headers })
    }
    updateVariant(id: number, variant: Product) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.put(this.baseUrl + `variant/${id}`, JSON.stringify(variant), { headers })
    }
}