import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from './auth.service';
import { HandlerService } from './handler.service';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  private baseUrl: string;

  constructor(private http: HttpClient, private authService: AuthService,
    private handleService: HandlerService) {
    this.baseUrl = this.handleService.getBaseUrl();
  }

  //CREATE OPENING STOCKS
  createOpeningStockCustom(openingStock: any[], feeder_type: string, distribution_id, sole_id, factory_id) {
    let feeder: string = ``;
    switch (feeder_type) {
      case 'distribution':
        feeder = `distribution_id=${distribution_id}`;
        break;
      case 'sole':
        feeder = `sole_id=${sole_id}`
        break;
      case 'factory':
        feeder = `factory_id=${factory_id}`
        break;
      default:
        break;
    }
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let final_url = this.baseUrl + `stock/opening_stock/?${feeder}`;
    console.log(final_url);
    return this.http.put(final_url, openingStock, { headers })
  }

  //GET STOCK REPORT DIST
  getStockReportDist(feeder_type: string, distribution_id: number, factory_id: number, sole_id: number, start_date, end_date, include_boxes) {
    let feeder: string = ``;
    switch (feeder_type) {
      case 'distribution':
        feeder = `distribution_id=${distribution_id}`;
        break;
      case 'sole':
        feeder = `sole_id=${sole_id}`
        break;
      case 'factory':
        feeder = `factory_id=${factory_id}`
        break;
      default:
        break;
    }
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let final_url = this.baseUrl + `stock/stock_report?${feeder}&start_date=${start_date}&end_date=${end_date}`;
    console.log(include_boxes)
    if (include_boxes === 'false' || include_boxes === false) {
      console.log(include_boxes)
      final_url = final_url + `&include_boxes=false`
    }
    console.log(final_url);
    return this.http.get(final_url, { headers });
  }

  getCreditReport(params, body) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let final_url = this.baseUrl + `secondary_reports/credit_report`;
    return this.http.post(final_url, body, { params, headers });
  }

  //GET last opening date STOCK REPORT 
  getLastOpeningDate(feeder_type: string, distribution_id: number, factory_id: number, sole_id: number, stock_id?: number) {

    let feeder: string = ``;
    let stock_id_string = ``;
    switch (feeder_type) {
      case 'distribution':
        feeder = `distribution_id=${distribution_id}`;
        break;
      case 'sole':
        feeder = `sole_id=${sole_id}`
        break;
      case 'factory':
        feeder = `factory_id=${factory_id}`
        break;
      default:
        break;
    }
    if (stock_id) {
      stock_id_string = `&stock_id=${stock_id}`
    } else {
      stock_id_string = ``;
    }

    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let final_url = this.baseUrl + `stock/get_last_opening_date?${stock_id_string}&${feeder}`;
    // console.log(final_url);
    return this.http.get(final_url, { headers });
  }

  addFactoryProduction(body, factory_id: number) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let final_url = this.baseUrl + `stock/add_factory_production/?factory_id=${factory_id}`;
    return this.http.put(final_url, JSON.stringify(body), { headers });
  }


}
