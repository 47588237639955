export class User {
    id?: number;
    user_type: string;
    cell?: string;
    email?: string;
    username: string;
    disabled: boolean;
    password?: string;

    constructor(
        email?: string,
        username?: string,
        user_type?: string,
        disabled?: boolean,
        password?: string,
    ) {

        this.email = email;
        this.username = username;
        this.user_type = user_type;
        this.disabled = disabled;
        this.password = password;
    }

}
