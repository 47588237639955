import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Factory } from '../distribution/models/factory.model';
import { LoginService } from './login.service';
import { HandlerService } from './handler.service';


@Injectable({
  providedIn: 'root'
})
export class FactoryService {

  private baseUrl: string;
  constructor(
    private http: HttpClient,
    private handleService: HandlerService) {
    this.baseUrl = this.handleService.getBaseUrl();
  }

  //GENERIC FUNCTION, resource IS THE url OF THE RESOURCE
  getResource(resource: string) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.baseUrl + resource, { headers });
  }
  createFactory(factory: Factory) {
    let request_body = JSON.stringify(factory);
    console.log(request_body);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.baseUrl + `factory/`, request_body, { headers })
  }
  viewFactories() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.baseUrl + `factory/all`, { headers })
  }
  viewfactory(id: number) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.baseUrl + `factory/${id}`, { headers })
  }
  updateFactory(id: number, factory: Factory) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(this.baseUrl + `factory/${id}`, JSON.stringify(factory), { headers })
  }

}
