import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { Shops, ShopType } from '../shops/shops';
import { HandlerService } from './handler.service';

@Injectable(
    { providedIn: 'root' }
)
export class ShopService {
    private baseUrl: string;

    constructor(
        private http: HttpClient,
        private loginService: LoginService,
        private handleService: HandlerService,
    ) {
        this.baseUrl = handleService.getBaseUrl();
    }

    createShop(shop: Shops) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        let x = JSON.stringify(shop)
        return this.http.post(this.baseUrl + `shop/old`, JSON.stringify(shop), { headers })
    }

    viewShops(params?: HttpParams) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(this.baseUrl + `shop/all`, { params, headers })
    }

    viewShop(id: number) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(this.baseUrl + `shop/${id}`, { headers })
    }

    updateShop(id: number, shop: Shops) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.put(this.baseUrl + `shop/${id}`, JSON.stringify(shop), { headers })
    }

    activateShop(ids: number[], params: HttpParams) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.put(this.baseUrl + `shop/activate_shop`, ids, { params, headers })
    }
    approvedshops(ids: number[], params: HttpParams) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(this.baseUrl + `shop/approve_shop`, ids, { params, headers })
    }
    //assign routes
    assignRoutes(ids: number[], route_id: number) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(this.baseUrl + `route/add_shops_in_route/${route_id}`, ids, { headers })
    }

    //unassign routes
    unassignRoutes(ids: number[]) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        let body: any = ids;
        return this.http.request('delete', this.baseUrl + `route/shops/`, { body, headers })
    }

    createShopType(shopType: ShopType) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post(this.baseUrl + `shop_type/`, JSON.stringify(shopType), { headers })

    }

    viewShopTypes() {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(this.baseUrl + `shop_type/all`, { headers })
    }

    viewShopType(id: number) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(this.baseUrl + `shop_type/${id}`, { headers })
    }

    updateShopType(id: number, shopType: ShopType) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.put(this.baseUrl + `shop_type/${id}`, JSON.stringify(shopType), { headers })
    }

}



