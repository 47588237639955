import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { HandlerService } from "./handler.service";

@Injectable({
  providedIn: "root",
})
export class GodModeService {
  baseUrl: string;
  constructor(
    private http: HttpClient,
    private handlerService: HandlerService
  ) {
    this.baseUrl = this.handlerService.getBaseUrl();
  }

  getScopeList() {
    // http://api.dev.secret.tasteland.com.pk/api/scope/all
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let baseUrl = this.baseUrl + `scope/all`;
    return this.http.get(baseUrl, { headers });
  }

  getSingleScopeList(id) {
    // https://api.dev.secret.tasteland.com.pk/api/scope/2
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let baseUrl = this.baseUrl + `scope/${id}`;
    return this.http.get(baseUrl, { headers });
  }

  createUserGroup(body) {
    // http://api.dev.secret.tasteland.com.pk/api/user_group
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let baseUrl = this.baseUrl + `user_group/`;
    return this.http.post(baseUrl, body, { headers });
  }

  getUserGroups(
    include_assigned_users: boolean = true,
    include_scope: boolean = true
  ) {
    // https://api.dev.secret.tasteland.com.pk/api/user_group/all?include_scope=true&include_assigned_users=true
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let baseUrl =
      this.baseUrl +
      `user_group/all?include_scope=${include_scope}&include_assigned_users=${include_assigned_users}`;
    return this.http.get(baseUrl, { headers });
  }

  assignUserToGroup(user_group_id, body) {
    // http://api.dev.secret.tasteland.com.pk/api/user_group/assign/Buser_group_id
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let baseUrl = this.baseUrl + `user_group/assign/${user_group_id}`;
    return this.http.post(baseUrl, body, { headers });
  }

  unAssignUserToGroup(body) {
    // https://api.dev.secret.tasteland.com.pk/api/user_group/assign/
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let baseUrl = this.baseUrl + `user_group/assign/`;
    return this.http.request("delete", baseUrl, { body, headers });

    // return this.http.delete(baseUrl, bod)
  }
  getAllUsers(body) {
    // https://api.dev.secret.tasteland.com.pk/api/user_group/users_for_assignment?include_inactive=true

    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let baseUrl =
      this.baseUrl + `user_group/users_for_assignment?include_inactive=true`;
    return this.http.post(baseUrl, body, { headers });
  }

  //GET USER GROUP
  getUserGroup(id) {
    // https://api.dev.secret.tasteland.com.pk/api/user_group/1
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let baseUrl = this.baseUrl + `user_group/${id}`;
    return this.http.get(baseUrl, { headers });
  }

  //UPDATE USER GROUP
  updateUserGroup(id, body) {
    // https://api.dev.secret.tasteland.com.pk/api/user_group/1
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let baseUrl = this.baseUrl + `user_group/${id}`;
    return this.http.put(baseUrl, body, { headers });
  }

  //DELETE USER GROUP
  deleteUserGroup(id) {
    // https://api.dev.secret.tasteland.com.pk/api/user_group/1
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let baseUrl = this.baseUrl + `user_group/${id}`;
    return this.http.delete(baseUrl, { headers });
  }
}
