import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HandlerService } from './handler.service';

@Injectable({
  providedIn: 'root'
})
export class DeliverymanService {
  private baseUrl: string;
  token: string;

  constructor(
    private http: HttpClient,
    private handleService: HandlerService
  ) {
    this.baseUrl = this.handleService.getBaseUrl();

  }

  createDeliverMan(value) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    let final_url = this.baseUrl + `deliveryman/with_employee`;
    return this.http.post(final_url, JSON.stringify(value), { headers })
  }

  getDeliverymans(dist_id: number, route_id?: number) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    let final_url = this.baseUrl + `deliveryman/all`;
    let dist_added: boolean = false;
    if (dist_id) {
      dist_added = true;
      final_url = final_url + `?distribution_id=${dist_id}`
      if (route_id) {
        final_url = final_url + `&route_id=${route_id}`
      }
    }
    if (route_id && !dist_added) {
      final_url = final_url + `?route_id=${route_id}`
    }
    console.log(final_url);
    return this.http.get(final_url, { headers })
  }

  //filter delivery man based on id
  getFilteredDeliverymans(deliveryman_id: number) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    let final_url = this.baseUrl + `deliveryman/${deliveryman_id}`;

    return this.http.get(final_url, { headers })
  }

  //UPDATE DELIVERYMAN

  updateDeliverMan(value, deliveryman_id: number) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    let final_url = this.baseUrl + `deliveryman/${deliveryman_id}`;
    return this.http.put(final_url, JSON.stringify(value), { headers })
  }


}
