import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginService } from './login.service';
import { HandlerService } from './handler.service';

@Injectable({
  providedIn: 'root'
})
export class TownService {
  private baseUrl: string;
  constructor(private http: HttpClient, private handleService: HandlerService, private loginService: LoginService) {
    this.baseUrl = handleService.getBaseUrl();
  }

  getTowns() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.baseUrl + 'town/all', { headers });
  }

}



