import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HandlerService } from './handler.service';

@Injectable({
  providedIn: 'root'
})
export class ShopCreditService {
  private baseUrl: string;
  token: string;

  constructor(
    private http: HttpClient,
    private handleService: HandlerService,
  ) {
    this.baseUrl = this.handleService.getBaseUrl();

  }

  activateCredit(body: any) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let url = this.baseUrl + `shop_credit/activate_credit`

    return this.http.post(url, JSON.stringify(body), { headers })
  }
  deactivateCredit(shop: any) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let url = this.baseUrl + `shop_credit/deactivate_credit?shop_id=${shop}`

    return this.http.post(url, { headers })
  }
  getShopCredit(shop: number) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let url = this.baseUrl + `shop_credit/get_shop_credit?shop_id=${shop}`;

    return this.http.get(url, { headers })
  }
  payCredit(shopId: number, payment: number) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let url = this.baseUrl + `shop_credit/pay/${shopId}?payment=${payment}`
    return this.http.post(url, { headers })
  }
}
