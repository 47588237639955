import { User } from "src/app/distribution/models/user.model";

export class Employee {
  id: number;
  first_name: string;
  last_name: string;
  cnic: string;
  designation: string;
  dob: string;
  join_date: string;
  resign_date?: string;
  base: string;
  email: string;
  cell: string;
  landline: string;
  address: string;
  gross_salary: number;

  active?: String;
  fk_user: number;

  user?: User;
}
